import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import PostTitle from "../components/postTitle"

const StyledPosts = styled.div`
  margin-top: 2em;
`

const NotFoundPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => edge.node.frontmatter.publish === true) // You can filter your posts based on some criteria
    .map(edge => <PostTitle key={edge.node.id} post={edge.node} />)

  return (
    <Layout>
      <SEO title="404: Not found" description="404 not found error page" />
      <Section height="auto" backgroundColor="#fff2fc">
        <h1 style={{ "margin-top": "1em" }}>Oops!</h1>
        <h2>
          Paiseh! Cannot find what you were looking for leh...
          <span role="img" aria-label="grinning face with sweat emoji">
            😅
          </span>
        </h2>
        {/* <h3 style={{'margin-top': '4em'}}>How about checking out my latest blog posts? <span role="img" aria-label="smirking face emoji">😏</span></h3> */}
        {/* <StyledPosts>{Posts}</StyledPosts> */}
      </Section>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 5
      filter: { frontmatter: { publish: { eq: true } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            publish
          }
        }
      }
    }
  }
`
