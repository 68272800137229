/**
 * Component to show links to blog posts in /blog
 */

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledLink = styled(Link)`
  color: black;

  &:hover {
    color: black;
    text-decoration: none;
    font-weight: bold;
  }
`

const PostTitle = ({ post }) => (
  <div>
    <StyledLink to={post.frontmatter.slug}>
      <p>
        {post.frontmatter.title} ({post.frontmatter.date})
      </p>
    </StyledLink>
  </div>
)

export default PostTitle
